<template>
  <!-- 新闻 -->
  <div class="new">
    <div class="bannerBox">
      <img src="https://img.kcgeis.com/6694506297.jpg" alt="">
    </div>
    <div class="bannerTitleBox">
      <div class="titleBox">
        <div class="title">
          科普资讯
        </div>
      </div>
    </div>
    <!-- 正文 -->
    <div class="newBox">
      <!-- 左侧列表按钮 -->
      <div class="leftBtnBox">
        <div class="btnBox" @click="$router.go(-1)">
          返回
        </div>
      </div>
      <!-- 右侧信息列表 -->
      <div class="rightBox">
        <span>{{ newsTitle }}</span>
        <hr>
        <div class="newsList">
          <div v-html="description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews } from '../api/news';


export default {
  name: 'NewContentIndex',
  data() {
    return {
      id: '',
      // newsList: [],
      description: '',
      newsTitle: '',
  
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getNews();
  },

  methods: {
    async getNews() {
      try {
        const { id } = this;
        const params = {
          id,
        };
        const { data } = await getNews(params);
        const { description, newsTitle } = data;
        this.description = description;
        this.newsTitle = newsTitle;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new {
  width: 100%;

  .bannerBox {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .bannerTitleBox {
    width: 100%;

    .titleBox {
      width: 1200px;
      margin: auto;
      height: 100%;
      position: relative;
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 230px;
        height: 65px;
        background-color: #018835;
        font-size: 22px;
        color: white;
        line-height: 65px;
        text-indent: 1em;
      }
    }
  }
  .newBox {
    width: 1200px;
    margin: auto;
    display: flex;
    .leftBtnBox {
      width: 230px;
      color: white;
      .btnBox {
        text-indent: 1.2em;
        font-size: 14px;
        width: 100%;
        height: 45px;
        margin: 2px 0;
        background-color: #666;
        line-height: 45px;
        &:hover {
          background-color: #018835;
        }
      }
    }
    .rightBox {
      width: 960px;
      min-height: 1000px;
      padding: 60px 0;
      span {
        font-size: 22px;
        color: #222;
      }
      hr {
        margin: 15px 0 20px 0;
        width: 25px;
        height: 5px;
        border: none;
        background-color: #018835;
      }
      .newsList {
        overflow: hidden;
        height: 100%;
        width: 100%;
      }
      img {
        width: 50%;
      }
    }
  }
  // ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  //   background-color: #f10203;
  //   color: #fff;
  // }
}
</style>
